<template>
    <div class="level">
        <div class="level-grade">
            <!--当前等级开始-->
            <div class="level-grade-now">
                <div class="now-con flex" :class="levelInfo.next_level_difference == 0 ? 'vertical-center' : ''">
                    <div class="grade-now-lv" >LV{{ levelInfo.level }}</div>
                    <div class="grade-experience">
                        <div class="experience-text" v-if="levelInfo.next_level_difference > 0">还差{{levelInfo.next_level_difference}}经验值升级</div>
                        <van-progress class="experience-progress" track-color="#252531" :show-pivot="false" color="linear-gradient(90deg, #F8D9AD, #D6A364)"
                                      :percentage="percentage_num.toFixed(2)" stroke-width="5" />

                            <div class="experience-num flex">{{levelInfo.experience}}<div class="flex" v-if="levelInfo.next_level_experience !== 0">/{{levelInfo.next_level_experience}}</div> </div>
                    </div>
                </div>

                <div  class="experience-swiper">
                    <div class="experience-item" v-for="(item,index) in levelInfo.levels" :key="index" @click="changeLevels(item,index)">
                        <div class="experience-wrap align-center" :class="{ 'experience-item-active' : level_num == index }">
                            <div class="experience-text">LV{{item.level}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--当前等级结束-->
            <!--下一等级权益开始-->
            <div class="level-grade-next">
                <div class="grade-next-title"> Lv{{currentData.level ? currentData.level : levelInfo.level}}权益奖励</div>
                <div class="interests">
                    <div class="interests-item" v-for="(item,index) in equityConfig.slice(0,level_num)" :key="index">{{item}}</div>
                    <div class="interests-item">可兑换同等级商品</div>
                    <div class="interests-item">可解锁更多任务</div>
                </div>
            </div>
            <!--下一等级权益结束-->

            <!--积分获取方式开始-->
            <div class="level-obtain">
                <div class="obtain-title">积分、经验值获取</div>
                <div class="obtain-go">
                    <img class="obtain-go-img" src="~assets/images/user/gift.png"/>
                    <div class="obtain-go-text">做任务，得积分、经验值</div>
                    <div class="obtain-go-task" @click="taskLink">去做任务</div>
                </div>
            </div>
            <!--积分获取方式结束-->

            <!--注意事项开始-->
            <div class="careful">
                <div class="careful-title">注意事项</div>
                <div class="careful-con">
                    <div class="careful-item">
                        1、可通过点击等级中心查看此页面相关信息。
                    </div>
                    <div class="careful-item">
                        2、升级奖励仅在第一次升级时获得，由于违规扣除积分、经验值后重复升级的用户不再发放奖励。
                    </div>
                    <div class="careful-item">
                        3、请采用正常合理的途径获取积分、经验值，使用违规或者作弊行为获取积分、经验值，一经发现按相关规定处罚。
                    </div>
                </div>
            </div>
            <!--注意事项结束-->
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
  name: "level",
  data(){
    return{
      levelInfo: {},
      level_num: -1,
      currentData: {},
      levelConfig: {},
      percentage_num: 0,
      equityConfig: ['积分换礼','积分换课','积分换书','积分换训练营','积分换线下课','积分换讲席营']
    }
  },
  created() {
    // 获取等级信息
    this.getLevel()
  },
    mounted() {
        this.$nextTick(()=>{
            this.$util.domMinHeight()
        })
    },
  methods: {
  //  获取等级信息
    getLevel(){
      let url = this.$api.UserLevel;
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.levelInfo = res.data.data;
          if(parseFloat(this.levelInfo.next_level_experience) == 0){
              this.percentage_num = 100;
          }else {
              let speed_num = (parseFloat(this.levelInfo.experience) / parseFloat(this.levelInfo.next_level_experience))*100;
              if(speed_num > 100){
                  speed_num = 100
              }else if(speed_num < 0){
                  speed_num = 0
              }
              this.percentage_num = speed_num
          }
          for (let i=0; i< this.levelInfo.levels.length;i++){
            if(this.levelInfo.level == this.levelInfo.levels[i].level){
              this.level_num = i;
              this.$nextTick(()=>{
                let sidebar_w = $('.experience-swiper').scrollLeft();
                let current_left = $('.experience-swiper').children().eq(this.level_num).position().left;
                $('.experience-swiper').animate({
                  scrollLeft: current_left - 25 + sidebar_w
                },300)
              })
            }
          }
        }
      })
    },
  //  等级选择
    changeLevels(item,index){
      this.currentData = item;
      this.level_num = index;
      this.getLevelConfig(item.id);
      this.$nextTick(()=>{
        let sidebar_w = $('.experience-swiper').width();
        let current_left = $('.experience-swiper').children().eq(this.level_num).position().left;
        $('.experience-swiper').animate({
          scrollLeft: current_left - (sidebar_w - ($('.experience-swiper').children().eq(this.level_num).width()))/2
        },300)
      })
    },
  //  获取等级配置详情
    getLevelConfig(id){
      let url = this.$api.UserLevel + '/' + id;
      this.$http.get(url,true).then(res =>{
        if(res.data.success){
          this.levelConfig = res.data.data;
        }
      })
    },
  //  做任务
    taskLink(){
      this.$router.push({path: '/task'})
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "~assets/scss/user/level/level.scss";
</style>